export var UrlHelper;
(function (UrlHelper) {
    function buildUrl(url, parameters) {
        let qs = "";
        for (const key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                const value = parameters[key];
                qs +=
                    encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
            }
        }
        if (qs.length > 0) {
            qs = qs.substring(0, qs.length - 1); //chop off last "&"
            url = url + "?" + qs;
        }
        return url;
    }
    UrlHelper.buildUrl = buildUrl;
    function getUrl(url) {
        //let isLocalhost = window.location.href.indexOf('localhost:') != -1;
        //if (url.indexOf("?") != -1) {
        //    url += "&" + constants.AppVersion;
        //}
        //else {
        //    url += "?" + constants.AppVersion;
        //}
        return url;
    }
    UrlHelper.getUrl = getUrl;
    function redirectToSignIn() {
        localStorage.setItem('IdToken', "");
        var url = window.location.href;
        var arr = url.split("/");
        var Domain = arr[0] + "//" + arr[2];
        window.location.href = Domain + "/" + "Session/SigIn?CurrentController=" + arr[3];
    }
    UrlHelper.redirectToSignIn = redirectToSignIn;
})(UrlHelper || (UrlHelper = {}));
