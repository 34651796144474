import axios from 'axios';
import { setupResponseInterceptor } from './setup-response-interceptor';
import { setupRequestInterceptor } from './setup-request-interceptor';
export let HttpService = axios.create();
export const initHttpService = (store, router) => {
    HttpService = axios.create({
    //baseURL: store && store.state.app.config.api.baseUrl,
    });
    HttpService.store = store;
    HttpService.router = router;
    setupRequestInterceptor(HttpService);
    setupResponseInterceptor(HttpService);
};
