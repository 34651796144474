import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/common/locales/i18n/en_US.json';
import ja from './i18n/ja-JP.json';
Vue.use(VueI18n);
const numberFormats = {
    'en': {
        currency: {
            style: 'currency', currency: 'USD', currencyDisplay: 'name'
        }
    },
    'ja': {
        currency: {
            style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
        }
    }
};
const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'ja': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    }
};
export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            lang: en,
        },
        ja: {
            lang: ja,
        },
    },
    numberFormats: numberFormats,
    dateTimeFormats: dateTimeFormats
});
